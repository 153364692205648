import { TitleH2, TitleMain } from "@/StyledComponents/FrontendStyled";
import Image from "next/image";
import { useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { TechnologiesIconTab1 } from "../../DummyData";
import {
  InnerTabsButton,
  TabInnerDiv,
  TechnologiesButtons,
  TechnologiesContant,
  TechnologiesContantMain,
  TechnologiesTabsMain,
} from "../../StyledComponents/LandingStyled";
import Link from "next/link";

export default function TechnologiesLogoHome() {
  const [openTab, setOpenTab] = useState("1");

  return (
    <TechnologiesTabsMain className="p-b-section p-t-section">
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
        <TitleMain>
          <TitleH2>Technologies we work with</TitleH2>
        </TitleMain>
      </AnimationOnScroll>
      <TechnologiesContantMain>
        <TechnologiesButtons>
          <InnerTabsButton
            className={openTab === "1" ? "active" : ""}
            onClick={() => setOpenTab("1")}
          >
            Mobile
          </InnerTabsButton>
          <InnerTabsButton
            className={openTab === "2" ? "active" : ""}
            onClick={() => setOpenTab("2")}
          >
            Front End{" "}
          </InnerTabsButton>
          <InnerTabsButton
            className={openTab === "4" ? "active" : ""}
            onClick={() => setOpenTab("4")}
          >
            Backend{" "}
          </InnerTabsButton>
          <InnerTabsButton
            className={openTab === "5" ? "active" : ""}
            onClick={() => setOpenTab("5")}
          >
            Frameworks{" "}
          </InnerTabsButton>
          <InnerTabsButton
            className={openTab === "10" ? "active" : ""}
            onClick={() => setOpenTab("10")}
          >
            CMS{" "}
          </InnerTabsButton>
          <InnerTabsButton
            className={openTab === "7" ? "active" : ""}
            onClick={() => setOpenTab("7")}
          >
            Database{" "}
          </InnerTabsButton>
          <InnerTabsButton
            className={openTab === "8" ? "active" : ""}
            onClick={() => setOpenTab("8")}
          >
            DevOps{" "}
          </InnerTabsButton>
          <InnerTabsButton
            className={openTab === "9" ? "active" : ""}
            onClick={() => setOpenTab("9")}
          >
            Ecommerce{" "}
          </InnerTabsButton>
        </TechnologiesButtons>
      </TechnologiesContantMain>

      <TechnologiesContant>
        {TechnologiesIconTab1.map(
          (d) =>
            openTab === d.category && (
              <TabInnerDiv className="TabInnerDiv" key={d.id}>
                <Link
                  className="relative flex flex-col items-center justify-center z-10 text-center"
                  href={d.link}
                  passHref
                >
                  <Image
                    //className="dark-img12"
                    className={`${
                      d.Logo_name === "IOS"
                        ? "dark-imgHtl"
                        : d.Logo_name === "Next Js"
                        ? "dark-imgHtl"
                        : d.Logo_name === "Express Js"
                        ? "dark-imgHtl"
                        : d.Logo_name === "Django"
                        ? "dark-imgHtl"
                        : d.Logo_name === "Gradle"
                        ? "dark-imgHtl"
                        : d.Logo_name === "Squarespace"
                        ? "dark-imgHtl"
                        : "dark-img12"
                    }`}
                    src={d.Icon_img}
                    width={62}
                    height={62}
                    alt={d.Logo_name + " " + "Technologies"}
                    loading="lazy"
                  />

                  <p className="mt-[1rem] mb-[1rem] font-medium text-[14px]">
                    {d.Logo_name}
                  </p>
                </Link>
              </TabInnerDiv>
            )
        )}
      </TechnologiesContant>
    </TechnologiesTabsMain>
  );
}
